import React, { useContext, useState } from 'react'
import { Form, Button, Input, Table, Modal, Spin, Comment, Avatar } from 'antd'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { CSVLink } from 'react-csv'

import { MemberShowcase } from 'store'
import './index.scss'
import { toJS } from 'mobx'

const { Search } = Input
const MemberShowCase = ({ showMembers, setShowMembers }) => {
  const {
    members,
    membersLoading,
    filterMembers,
    clearMemberFilter,
  } = useContext(MemberShowcase)

  const [premiumUserWaringModel, setPremiumUserWaringModel] = useState(false)

  console.log(toJS(members))
  const memberShowcaseColumn = [
    {
      title: 'Name',
      dataIndex: 'profile_url',
      align: 'left',
      render(val, record) {
        return (
          <div className="member-name-column">
            <Comment
              author={
                <h3>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      record.linkedin_profile_url
                        ? `https://${record.linkedin_profile_url}`
                        : null
                    }
                  >{`${record.first_name} ${record.last_name}`}</a>
                </h3>
              }
              avatar={
                <Avatar
                  size="default"
                  src={val}
                  icon="user"
                  alt={record.last_name}
                />
              }
              content={
                <>
                  {record.is_premium ? (
                    <a href={`mailto:${record.calendar_email || record.email}`}>
                      {record.calendar_email || record.email}
                    </a>
                  ) : (
                    <p
                      className="noselect"
                      onClick={() => setPremiumUserWaringModel(true)}
                    >
                      {record.calendar_email || record.email}
                    </p>
                  )}
                </>
              }
            />
          </div>
        )
      },
    },
    {
      title: 'Location',
      align: 'center',
      dataIndex: 'location',
      sorter: (a, b) => a?.location?.length - b?.location?.length,
    },
    {
      title: 'Intros',
      align: 'center',
      dataIndex: 'introcount',
      sorter: (a, b) => a.introcount - b.introcount,
      render(value, record) {
        return <b>{+value + +record.total_ondemand_meetings}</b>
        // return <b>{+value}</b>
      },
    },
    {
      title: 'Last intro',
      align: 'center',
      dataIndex: 'lastintro',
      sorter: (a, b) => {
        if (a && b) return a.lastintro - b.lastintro
      },
      render(value, record) {
        let date = ''
        if (!value && !record?.last_ondemand_meeting) {
          // if (!value) {
          return <p>No intros</p>
        } else {
          // date = value
          if (record?.lastintroepoch <= record?.lastondemandepoch) {
            date = record?.last_ondemand_meeting
          } else {
            date = value
          }
        }
        return (
          <p>
            {date?.split(' ')[0]}
            {/* {moment(parseInt(value)).calendar(null, {
              lastDay: '[Yesterday]',
              sameDay: '[Today]',
              nextDay: '[Tomorrow]',
              lastWeek: '[last] dddd',
              nextWeek: 'dddd',
              sameElse: 'L',
            })} */}
          </p>
        )
      },
    },
    {
      title: 'Joined Date',
      dataIndex: 'joined_on',
      align: 'center',
      sorter: (a, b) => a.joined_on - b.joined_on,
      render(value) {
        return (
          <p>
            {moment(parseInt(value)).calendar(null, {
              lastDay: '[Yesterday]',
              sameDay: '[Today]',
              nextDay: '[Tomorrow]',
              lastWeek: '[last] dddd',
              nextWeek: 'dddd',
              sameElse: 'L',
            })}
          </p>
        )
      },
    },
  ]

  const headers = [
    { label: 'Data Date', key: 'data_date' },
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Updated Email', key: 'updated_email' },
    { label: 'Calendar Mail', key: 'calendar_email' },
    { label: 'Industry ', key: 'industry' },
    { label: 'Calendar Linked', key: 'onboarded' },
    { label: 'Location', key: 'location' },
    { label: 'Company', key: 'company' },
    { label: 'Title', key: 'title' },
    { label: 'Linikedin url', key: 'linkedin_profile_url' },
    { label: 'Group Name', key: 'group_name' },
    { label: 'Last Intro', key: 'lastintro' },
    { label: 'Total Intros', key: 'total_intros' },
    { label: 'Total RSVP', key: 'reason' },
    { label: 'Total Meetings', key: 'total_meetings_count' },
    { label: 'Last Meeting', key: 'last_meeting_date' },
    // { label: 'Total_ondemand_meetings', key: 'total_ondemand_meetings' },
    // { label: 'Last_ondemand_meeting', key: 'last_ondemand_meeting' },
  ]

  return (
    <Modal
      title="Members Info: "
      centered
      width={1100}
      visible={showMembers}
      maskClosable
      footer={null}
      destroyOnClose
      onCancel={() => setShowMembers(false)}
    >
      <>
        {membersLoading ? (
          <div className="flexing">
            <Spin size="large" />
          </div>
        ) : (
          <>
            <div className="ten-px-margin">
              <Search
                className="input-search-antd"
                placeholder="Search for Members"
                // onSearch={value => filterGroups(value)}
                onChange={e => filterMembers(e.target.value)}
              />
              <Button type="danger" onClick={() => clearMemberFilter()}>
                Clear
              </Button>
              <div style={{ display: members.length ? 'inline' : 'none' }}>
                <div className="memeber-data-download-btn">
                  {members?.[0]?.is_premium ? (
                    <CSVLink
                      data={members}
                      headers={headers}
                      filename={members?.[0]?.group_name}
                    >
                      <p style={{ color: 'white' }}>Download Member Data</p>
                    </CSVLink>
                  ) : (
                    <Button
                      className="downloadBtn"
                      onClick={() => setPremiumUserWaringModel(true)}
                    >
                      Download Member Data
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <Table
              rowKey={'id'}
              bordered
              scroll={{ x: 1000 }}
              pagination={{ pageSize: 5 }}
              columns={memberShowcaseColumn}
              dataSource={members}
            />
            <Modal
              title="Unlock Premium Community Building Features"
              centered
              width={500}
              visible={premiumUserWaringModel}
              onCancel={() => setPremiumUserWaringModel(false)}
              footer={null}
            >
              <>
                <div className="container">
                  <p style={{ fontSize: '16px', marginBottom: '10px' }}>
                    Schedule a call with our solutions team to discuss best
                    options and platform features to harness for your community
                    building goals.
                  </p>
                  <Button>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://calendly.com/meeting-machine/meeting-machine-intros-demo"
                    >
                      Schedule Call
                    </a>
                  </Button>
                </div>
              </>
            </Modal>
          </>
        )}
      </>
    </Modal>
  )
}
export default Form.create()(observer(MemberShowCase))

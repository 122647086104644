import { observable, decorate, action } from 'mobx'
import {
  joinGroupService,
  createNewGroupService,
  updateGroupService,
  getGrpDetailService,
  sendInvitationService,
  acceptInvitationService,
  revokeInvitationService,
} from '../services/joinGroupService'
import { createContext } from 'react'

class JoinGroup {
  joinedGroups = []
  successMessage = ''
  grpDetails = null

  joinGroup = async (groupID, userID, lid, mg) => {
    this.successMessage = await joinGroupService(groupID, userID, lid, mg)
    return this.successMessage
  }

  getGrpDetails = async grpID => {
    this.grpDetails = await getGrpDetailService(grpID)
    return this.grpDetails
  }

  createGroup = async (
    groupName,
    isPublic,
    description,
    viaSignUpCommunity = false
  ) => {
    this.groups = await createNewGroupService(
      groupName,
      isPublic,
      description,
      viaSignUpCommunity
    )
    return this.groups
  }

  updateGroup = async (groupId, description, groupPrefernce) => {
    const updateGroupRes = await updateGroupService(
      groupId,
      description,
      groupPrefernce
    )
    return updateGroupRes
  }

  sendInvitation = async (group_id, user_email, owner_id) => {
    const sendInviteRes = await sendInvitationService(
      group_id,
      user_email,
      owner_id
    )
    return sendInviteRes
  }

  revokeInvitation = async (group_id, admin_id) => {
    const revokedInvite = await revokeInvitationService(group_id, admin_id)
    console.log('revokedInvite', revokedInvite, Boolean(revokedInvite.status))
    return Boolean(revokedInvite.status)
  }

  acceptInvitation = async token => {
    const acceptInviteRes = await acceptInvitationService(token)
    return acceptInviteRes
  }
}

decorate(JoinGroup, {
  joinedGroups: observable,
  joinGroup: action,
  grpDetails: observable,
  getGrpDetails: action,
})

export default createContext(new JoinGroup())

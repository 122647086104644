import { get, getWithParams, post } from './index'

export const getDeviceAccessToken = async () => {
  const response = await get({
    url: `/device/access-token`,
  })
  return response.data || {}
}

export const getLiveUsersMatch = async () => {
  const response = await get({
    url: `/user/ondemand-match`,
  })
  return response.data || {}
}

export const getIncomingCallUserDetail = async params => {
  const response = await getWithParams({
    url: `/user/incoming-call-user`,
    params,
  })
  return response.data || {}
}

export const getConferenceDetails = async params => {
  const response = await getWithParams({
    url: `/user/conference-match-user`,
    params,
  })
  return response.data || {}
}

export const updateUserProfile = async (type, inMeeting) => {
  const response = await post({
    url: `/user/update`,
    body: {
      type,
      inMeeting,
    },
  })
  return response.data
}
export const sendEmailOnIncompleteProfile = async () => {
  const response = await post({
    url: `/user/incomplete-profile`,
  })
  return response.data
}

export const setIncomingCallRecordingState = async shouldRecord => {
  const response = await post({
    url: `/user/update-recording-flag`,
    body: {
      record: shouldRecord,
    },
  })
  return response.data
}

import React, { useContext, useState } from 'react'
import {
  Form,
  Button,
  Input,
  message,
  Modal,
  Spin,
  Select,
  Avatar,
  Switch,
  Icon,
  notification,
  List,
} from 'antd'
import { observer } from 'mobx-react-lite'
import GroupSettingModal from './GroupSettingModal'
import {
  InterestGroupStoreContext,
  MemberShowcase,
  UserDetailContext,
} from 'store'
import './index.scss'
import { ShareGroup } from 'components'
import { CodePlugin } from './CodePlugins'

const Option = Select.Option

const MemberShowCase = ({
  form,
  showMyGroupInfoModal,
  setShowMyGroupInfoModal,
}) => {
  const [isEditGroup, setIsEditGroup] = useState(true)
  const [showShareGroup, setShowShareGroup] = useState(false)
  const [showImageModal, setShowImageModal] = useState(false)
  const [image, setImage] = useState({
    imageUrl: null,
    loading: false,
  })
  let { getFieldDecorator, validateFields } = form
  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
  }
  const { assignGroupToUser } = useContext(UserDetailContext)

  const { getPublicSignedUrl } = useContext(MemberShowcase)
  const {
    groupDetail,
    groupLoading,
    updateGroupDetail,
    groupAdminsIncludingOwner,
  } = useContext(InterestGroupStoreContext)

  const handleUpdateGroup = async () => {
    try {
      let publicUrl = null
      if (groupDetail && groupDetail.group_image) {
        const n = groupDetail.group_image.lastIndexOf('/')
        const result = groupDetail.group_image.substring(n + 1)
        publicUrl = await getPublicSignedUrl(result, groupDetail.id)
        setImage({ ...image, imageUrl: publicUrl })
      }
      setShowImageModal(true)
    } catch (error) {
      console.log(error)
    }
  }
  const onSubmit = e => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        try {
          if (values.groupName.length < 4) {
            return notification.error({
              message: 'Group name should be more then 3 characters',
            })
          }
          updateGroupDetail({
            groupId: groupDetail.id,
            group_name: values.groupName,
            description: values.groupDescription,
            groupPrefernce: !values.groupPrefernce,
            hasSpeedNetworking: values.hasSpeedNetworking,
          })
        } catch (error) {
          notification.error({
            message: 'Error occured while updaing...',
          })
        }
      }
    })
  }

  return (
    <>
      <GroupSettingModal
        showImageModal={showImageModal}
        setShowImageModal={setShowImageModal}
        image={image}
        setImage={setImage}
      />
      <Modal
        title={`Group info: ${
          isEditGroup ? 'Showing as admin view' : 'Showing as member view'
        }`}
        centered
        visible={showMyGroupInfoModal}
        maskClosable
        footer={null}
        width={800}
        onCancel={() => {
          setShowMyGroupInfoModal(false)
          setIsEditGroup(true)
        }}
      >
        <>
          {groupLoading ? (
            <div className="flexing">
              <Spin size="large" />
            </div>
          ) : (
            <>
              {/* <center>
                <Button
                  type="primary"
                  onClick={() => setIsEditGroup(isEditGroup => !isEditGroup)}
                  className="ten-px-margin"
                >
                  {isEditGroup ? 'Show as member' : 'View as admin'}
                </Button>
              </center> */}

              {isEditGroup && (
                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-col-5 ant-form-item-label">
                    <label>Group Manager</label>
                  </div>
                  <Select
                    className="ant-col ant-col-16 ant-form-item-control-wrapper select-admins"
                    style={{
                      width: '66.66666667%',
                    }}
                    onSelect={e => {
                      assignGroupToUser({
                        id: groupDetail.id,
                        admin_id: e,
                      })
                      message.success('Group assigned successfully ')
                      setShowMyGroupInfoModal(false)
                      setIsEditGroup(true)
                    }}
                    defaultValue={`${groupDetail?.currAdmin?.firstName} ${
                      groupDetail?.currAdmin?.lastName
                    }`}
                    showSearch
                  >
                    {groupAdminsIncludingOwner &&
                      groupAdminsIncludingOwner.length &&
                      groupAdminsIncludingOwner.map(val => {
                        return (
                          <Option key={val.admin_id} value={val.admin_id}>
                            <List.Item>
                              <List.Item.Meta
                                avatar={<Avatar src={val?.profile_url} />}
                                title={val?.admin_name}
                                description={val?.email}
                              />
                            </List.Item>
                          </Option>
                        )
                      })}
                  </Select>
                </div>
              )}
              <Form {...layout} onSubmit={onSubmit}>
                {!isEditGroup && (
                  <Form.Item
                    label="Group Manager"
                    style={{ marginBottom: '-5px' }}
                  >
                    <Form.Item
                      className="group-manager-fullname"
                      // label="Group Manager"
                      name="groupManager"
                    >
                      {getFieldDecorator('groupManager', {
                        initialValue: `${groupDetail?.currAdmin?.firstName} ${
                          groupDetail?.currAdmin?.lastName
                        }`,
                      })(<Input readOnly={true} />)}
                    </Form.Item>

                    <Form.Item
                      className="group-manager-fullname"
                      name="groupManagerEmail"
                    >
                      {getFieldDecorator('groupManagerEmail', {
                        initialValue: `${groupDetail?.currAdmin?.email}`,
                      })(<Input readOnly={true} />)}
                    </Form.Item>
                  </Form.Item>
                )}

                <Form.Item label="Group Name" name="groupName">
                  {getFieldDecorator('groupName', {
                    rules: [
                      { required: false, message: 'Group Name is required' },
                    ],
                    initialValue: groupDetail.groupName,
                  })(<Input readOnly={isEditGroup ? false : true} />)}
                </Form.Item>

                <Form.Item label="Group Description" name="groupDescription">
                  {getFieldDecorator('groupDescription', {
                    rules: [
                      { required: false, message: 'Description is required' },
                    ],
                    initialValue: groupDetail.description,
                  })(
                    <Input.TextArea
                      readOnly={isEditGroup ? false : true}
                      rows={5}
                    />
                  )}
                </Form.Item>

                <Form.Item label="Group Link" name="groupLink">
                  {getFieldDecorator('groupLink', {
                    rules: [
                      {
                        required: false,
                        message: 'Please input your Group Link!',
                      },
                    ],
                    initialValue: groupDetail.groupLink,
                  })(<Input readOnly={true} />)}
                </Form.Item>

                <CodePlugin group_id={groupDetail.id} />

                {isEditGroup ? (
                  <Form.Item label="Private Group">
                    {getFieldDecorator('groupPrefernce', {
                      valuePropName: 'checked',
                      initialValue: !groupDetail.isPublic,
                    })(
                      <Switch
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="cross" />}
                      />
                    )}
                  </Form.Item>
                ) : null}
                <Form.Item label="Speed Networking">
                  {getFieldDecorator('hasSpeedNetworking', {
                    valuePropName: 'checked',
                    initialValue: groupDetail.hasSpeedNetworking,
                  })(
                    <Switch
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="cross" />}
                    />
                  )}
                </Form.Item>
                {isEditGroup ? (
                  <div className="centered">
                    <div className="ant-row ant-form-item">
                      <ShareGroup
                        openModal={() => setShowShareGroup(true)}
                        show={showShareGroup}
                        groupToShare={groupDetail}
                        closeModal={() => setShowShareGroup(false)}
                      />
                    </div>
                    <Form.Item>
                      <Button
                        loading={groupLoading}
                        type="primary"
                        htmlType="submit"
                      >
                        Save Changes
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button onClick={e => handleUpdateGroup()}>
                        Settings
                      </Button>
                    </Form.Item>
                  </div>
                ) : null}
              </Form>
            </>
          )}
        </>
      </Modal>
    </>
  )
}
export default Form.create()(observer(MemberShowCase))
